<template>
  <div class="input-wrapper" :class="{ error, disabled }">
    <div class="check-wrapper" v-on:click="check()">
      <input
        type="checkbox"
        :checked="isChecked"
        :value="val"
        v-on:change="$emit('change', $event.target.value)"
      />
      <div class="checkbox" :class="{ checked: isChecked }">
        <div class="checkbox-inner"></div>
      </div>
      <label class="b1" :style="labelStyle">
        {{ label }}
        <b v-if="required">*</b>
      </label>
    </div>
  </div>
</template>

<style lang="less" scoped>
.input-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5em;
  position: relative;

  label.float-label {
    flex-basis: 100%;
    display: block;
    position: absolute;
    top: 15px;
    left: 15px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 200;
    transition: 0.25s;

    &.active {
      font-size: 0.6em;
      top: 10px;
    }
    // end active
  }

  .check-wrapper {
    display: flex;
    cursor: pointer;
    align-items: center;

    input[type="checkbox"] {
      display: none;
    }
    // end input

    .checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      min-width: 20px;
      height: 20px;
      box-shadow: 1px 1px 2px -1px rgba(255, 255, 255, 0.15),
        inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
      background: var(--checkbox);
      border: 1px solid #393d48;
      border-radius: 5px;
      appearance: none;

      .checkbox-inner {
        position: relative;
        left: 0px;
        top: -2px;
        // z-index: 99;
        width: 5px;
        height: 12px;
        border: solid var(--primary);
        border-width: 0;
        // display: none;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        // transition: 0.2s;
      }
      // end checkbox-inner

      &.checked {
        .checkbox-inner {
          max-width: 15px;
          max-height: 15px;
          // display: block;
          border-width: 0 2px 2px 0;
        }
        // end checkbox-inner
      }
      // end checked
    }

    label {
      position: relative;
      left: 8px;

      cursor: inherit;

      b {
        padding-left: 2px;
        color: @colors[input-required];
      }
    }
    // end label
  }
  // end check-wrapper

  select {
    display: none;
  }

  .mask {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.active {
      display: block;
    }
  }

  &.error {
    label {
      color: #e60022;
    }

    input[type="checkbox"] {
      background-color: #1a0306;

      &:focus {
        background-color: #47060f;
      }
    }
  }

  &.disabled {
    opacity: 0.5;

    .check-wrapper {
      cursor: default;
    }
  }
}
</style>

<script>
export default {
  name: "diyobo-checkbox",
  props: {
    label: String,
    placeholder: String,
    options: Array,
    required: Boolean,
    error: Boolean | String,
    val: String | Boolean,
    checked: Boolean,
    labelStyle: Object,
    disabled: Boolean,
  },
  data() {
    return {
      focused: false,
      isChecked: this.checked,
      expanded: false,
      value: null,
    };
  },
  watch: {
    checked: function(val) {
      this.isChecked = val;
    },
  },
  methods: {
    focus: function() {
      this.focused = true;
    },
    blur: function() {
      if (this.$refs.input.value.trim() != "") this.focused = true;
      else this.focused = false;
    },
    check: function() {
      if (!this.disabled) {
        if (this.isChecked) this.isChecked = false;
        else this.isChecked = true;
        this.$emit("input", this.isChecked, this.label);
      }
    },
    expand: function() {
      if (this.expanded) this.expanded = false;
      else this.expanded = true;
    },
    close: function() {
      this.expanded = false;
    },
  },
};
</script>
