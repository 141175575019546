<template>
  <div class="interests-search">
    <div class="search-wrapper">
      <label>
        Personal Interests / Hobbies
        <span>
          (must have a minimum of 10 interests in order to be able to use
          ToteTalk)
        </span>
      </label>
      <label v-if="interestInputErrorMsg != ''" class="error">
        {{
        interestInputErrorMsg
        }}
      </label>
      <input
        type="text"
        id="search-input"
        placeholder="Search interest / hobby name"
        :value="interestInput"
        v-on:input="search($event.target.value)"
        v-on:focus="topInterests()"
        autocomplete="off"
      />
      <div class="icon-wrapper">
        <font-awesome-icon icon="search" />
      </div>
    </div>
    <div v-bind:class="[active ? 'show-search-dropdown' : 'hide-search-dropdown']">
      <ul class="interest-input" v-if="interestInput.length == 0">
        <li
          class="interest"
          v-for="interest in topInterest"
          v-on:click="addInterest(interest)"
          :key="interest._id"
        >{{ interest.interest }}</li>
      </ul>
      <ul class="interest-input" v-else-if="interestInput.length > 0">
        <li class="interest" v-on:click="suggestInterest()">
          <div class="button-suggestion">
            {{ interestInput }}
            <button>Suggest</button>
          </div>
        </li>
        <li
          class="interest"
          v-for="interest in interestSearch"
          v-on:click="addInterest(interest)"
          :key="interest._id"
        >{{ interest.interest }}</li>
      </ul>
    </div>
  </div>
</template>

<style lang="less" scoped>
#app {
  .interests-search {
    .search-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 1.5em 0;
      position: relative;

      label {
        flex-basis: 100%;
        display: block;
        margin-bottom: 0.5em;
        
        font-size: 0.98em;

        span {
          color: #666666;
        }
      }
      // end label

      input[type="text"] {
        display: block;
        margin: auto;
        padding: 12px 14px;
        width: 100%;
        font-family: inherit;
        font-size: 1em;
        color: #ffffff;
        background-color: #181818;
        border: none;
        border-bottom: 1px solid #3b3b3b;
        border-radius: 8px;
        box-shadow: inset 0 2px 7px 2px #000000b9;
        outline: none;
        -webkit-appearance: none;
        transition: 0.4s;

        &:focus {
          padding-left: 16px;
          padding-right: 12px;
          background-color: #1f1f1f;
        }
      }
      // end input

      .icon-wrapper {
        position: absolute;
        right: 13px;
        bottom: 7px;
        font-size: 1.5em;
        color: #222222;

        svg {
          filter: drop-shadow(1px 1px 0 #161616d7);
        }
      }
      //end icon-wrapper

      .error {
        color: #e60022;
      }
    }
    // end search-wrapper
    .show-search-dropdown {
      background-color: #303030;
      margin-top: -52px;
      margin-bottom: 35px;
      border-radius: 0px 0px 8px 8px;
      box-shadow: 0 0 7px 2px #000000b9;
      max-height: 400px;
      overflow: auto;

      // scrollbar stuff only works in chrome
      &::-webkit-scrollbar {
        background-color: #474747;
        border-radius: 8px 0px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 8px 0px 0px 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #838181;
        border-radius: 0px 8px 8px 0px;
      }

      ul {
        list-style-type: none;
        padding: 0px 15px;
        color: #a8a7a7;
        // margin-top: 24px;

        &.interest-input {
          margin-top: 24px;
          margin-bottom: 0px;

          .button-suggestion {
            display: flex;
            // width: 49px;
            z-index: 5;

            button {
              padding: 0.25em;
              background-color: #252525;
              border-radius: 5px;
              color: #a8a7a7;
              border: none;
              font-size: 0.7em;
              font-weight: 600;
              width: 55px;
              margin-right: 200px;
              margin-left: 10px;
            }
            // end button
          }

          li {
            cursor: pointer;
            border-top: 1px solid rgb(65, 62, 62);
            padding: 5px 0px;
            transition: 0.4s;

            &:hover {
              padding-left: 16px;
              padding-right: 12px;
              background-color: #474747;
            }
          }
          // end li
        }
      }
      // end ul
    }
    // end show-search-dropdown
    .hide-search-dropdown {
      display: none;
    }
    // end hide-search-dropdown
  }
  // end interests-search
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faUnderline,
  faSleigh
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import debounce from "debounce";

library.add(faSearch);

export default {
  name: "interests-search",
  components: {
    FontAwesomeIcon
  },
  props: {
    interests: Array
  },
  data() {
    return {
      active: false,
      suggestion: false,
      duplicateSuggest: false,
      interestInput: "",
      searchInput: "",
      interestInputErrorMsg: "",
      interestSearch: [],
      topInterest: [],
      documentPos: undefined
    };
  },
  methods: {
    checkClickPos() {
      if (this.documentPos.id != "search-input") {
        this.active = false;
      }
    },
    topInterests() {
      let labels = [];
      for (let x = 0; x < this.interests.length; x++) {
        labels.push(this.interests[x].interest);
      }

      this.$axios
        .post(`${process.env.VUE_APP_SERVICE_URL}top-interests`, {
          interests: labels
        })
        .then(response => {
          this.topInterest = response.data;
        });
      this.active = true;
    },
    search(value) {
      let self = this;
      self.interestInput = value;

      let labels = [];
      for (let x = 0; x < this.interests.length; x++) {
        labels.push(this.interests[x].interest);
      }

      this.$axios
        .post(`${process.env.VUE_APP_SERVICE_URL}interest-search`, {
          input: value,
          interests: labels
        })
        .then(response => {
          this.interestSearch = response.data;
        });
    },
    addInterest(interest) {
      this.interestInput = "";
      this.$axios
        .post(`${process.env.VUE_APP_SERVICE_URL}get-interest`, {
          interest: interest.interest
        })
        .then(response => {
          let interest = response.data[0];
          this.$emit("input", interest);
          this.active = false;
        });
    },
    suggestInterest() {
      let self = this;
      this.$axios
        .post(`/buyers/update-profile/suggest-interest`, {
          interest: this.interestInput
        })
        .then(response => {
          console.log(response.data);
          if (response.data == null || response.data.blacklisted == false) {
            if (response.data == null || response.data.blacklisted == false) {
              let interests = self.$store.state.user.interests;
              let pendingInterests = self.$store.state.user.pendingInterests;
              let temp = false;

              for (let x = 0; x < interests.length; x++) {
                if (interests[x].interest == self.interestInput) {
                  temp = true;
                }
              }
              for (let x = 0; x < pendingInterests.length; x++) {
                if (pendingInterests[x].interest == self.interestInput) {
                  temp = true;
                }
              }

              if (temp == false) {
                self.addInterest({ interest: self.interestInput });
                self.interestInputErrorMsg = "";
              } else {
                self.interestInputErrorMsg = "Interest already exists";
                return;
              }
            } else {
              // self.addInterest(response.data);
              return;
            }

            this.$store.state.bus.$emit("alert", ModalContent.suggestInterest);
            window.scrollTo(0, 0);
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
          } else {
            this.$store.state.bus.$emit(
              "alert",
              ModalContent.blacklistedInterest
            );
            window.scrollTo(0, 0);
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
            self.interestInputErrorMsg = "";
            self.interestInput = "";
          }
        })
        .catch(error => console.log(error));
    }
  },
  // watch: {

  //     searchInput: function (newQuestion, oldQuestion) {

  //     this.getDebounced(this.searchInput)
  //     }
  // },
  // created() {
  //     this.getDebounced = debounce(this.debuncedSearch, 1000)
  // },
  mounted() {
    let self = this;
    document.addEventListener("click", function() {
      self.documentPos = event.target;
      self.checkClickPos();
    });
  }
};
</script>
