<template>
  <div class="dconnect-toggle">
    <label>
      ToteTalk
      <span>(Disabled / Enabled)</span>
    </label>
    <div class="switch-wrapper">
      <div class="mask" v-if="this.active == false"></div>
      <div class="toggle-switch" :class="{ active: checked }" v-on:click="toggle()">
        <div class="toggle-inner"></div>
      </div>
      <input type="checkbox" ref="checkbox" :checked="checked" />
    </div>
  </div>
</template>

<style lang="less" scoped>
#app {
  .dconnect-toggle {
    input[type="checkbox"] {
      display: none;
    }

    .switch-wrapper {
      display: flex;
      align-items: center;
      margin-top: 0.5em;
      position: relative;
      width: 40px;
      height: 20px;
      background-color: #181818;
      border-bottom: 1px solid #3b3b3b;
      border-radius: 20px;
      position: relative;

      .mask {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 10;
        border-radius: 20px;
        background-color: #3333339d;
      }

      .toggle-switch {
        display: flex;
        align-items: center;
        position: relative;
        width: 40px;
        height: 20px;
        background-color: #181818;
        border-bottom: 1px solid #3b3b3b;
        border-radius: 20px;
        box-shadow: inset 0 0 2px 1px #0e0d0db4;
        transition: 0.25s;
        position: relative;

        .toggle-inner {
          position: absolute;
          left: 3px;
          width: 15px;
          height: 15px;
          background-color: #63c377;
          background-image: linear-gradient(#63c377, #339f3e);
          border-radius: 50px;
          transition: 0.25s;
        }
        // end toggle-inner

        &.active {
          background-color: #385a40;

          .toggle-inner {
            left: 22px;
          }
        }
      }
      // end toggle-switch
    }

    label {
      
      font-size: 0.98em;

      span {
        color: #666666;
      }
    }
  }
  // end dconnect-toggle
}
</style>

<script>
export default {
  name: "dconnect-toggle",
  props: {
    interests: Array
  },
  data() {
    return {
      checked: null
    };
  },
  methods: {
    toggle() {
      if (this.checked == true) {
        this.checked = false;
        this.$axios
          .post(`${process.env.VUE_APP_SERVICE_URL}update-tote-talk`, {
            email: this.$store.state.user.email,
            totetalk: false
          })
          .then(response => {
            console.log(response.data);
          });
      } else if (this.checked == false) {
        this.checked = true;
        this.$axios
          .post(`${process.env.VUE_APP_SERVICE_URL}update-tote-talk`, {
            email: this.$store.state.user.email,
            totetalk: true
          })
          .then(response => {
            console.log(response.data);
          });
      }

      if (this.chat.session) {
        this.chat.session.call("com.diyobo.chat.totetalk", [
          this.user.id,
          this.checked
        ]);
      }
    }
  },
  computed: {
    active: {
      get() {
        let self = this;
        console.log(this.interests.length);
        if (this.interests.length >= 10 && this.checked == null) {
          this.$axios
            .post(`${process.env.VUE_APP_SERVICE_URL}update-tote-talk`, {
              email: this.$store.state.user.email,
              totetalk: true
            })
            .then(response => {
              console.log(response.data);
            });
          this.checked = true;
          return true;
        } else if (this.interests.length < 10) {
          this.checked = null;
          return false;
        } else {
          this.$axios
            .post(`${process.env.VUE_APP_SERVICE_URL}update-tote-talk`, {
              email: this.$store.state.user.email,
              totetalk: self.checked
            })
            .then(response => {
              console.log(response.data);
            });
          return true;
        }
      },
      set: function(val) {
        this.checked = val;
      }
    },
    user() {
      return this.$store.state.user;
    },
    chat() {
      return this.$store.state.chat;
    }
  },
  async mounted() {
    this.$axios
      .post(`${process.env.VUE_APP_SERVICE_URL}tote-talk-position`, {
        email: this.$store.state.user.email
      })
      .then(response => {
        console.log(response);
        // this.checked = response.data;
        this.active = response.data;
      });
  }
};
</script>
