<template>  
  <transition
    v-on:beforeEnter="beforeEnter"
    v-on:leave="leave"
  >
    <div class="qr-wrapper" v-if="active">
      <div class="mask"></div>
      <div class="qr-code-container">
        <font-awesome-icon class="exit" v-on:click="close" icon="times" />
        <h2>Use this QR code at any event you’ve purchased tickets to for entry.</h2>
        <img :src="qrCode" />
      </div>
    </div>
  </transition>
</template>

<style lang="less" scoped>
.qr-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10000;
  
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #2222229f;
    opacity: 1;
  }

  .qr-code-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 2;

    .exit {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 25px;
      cursor: pointer;
    }

    h2 {
      text-align: center;
      width: 100%;
    }

    img {
      margin-top: 20px;
      width: 235px;
      height: 235px;
    }
  }
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faTimes );

export default {
  name: "profile",
  components: {
    FontAwesomeIcon,
  },
  props: {
    qrCode: String,
    active: Boolean,
  },
  data() {
    return {
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    beforeEnter(el) {
      document.body.style.overflow = "hidden";
    },
    leave(el) {
      document.body.removeAttribute("style");
    },
  }
};
</script>
