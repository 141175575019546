<template>
  <div class="interests">
    <dConnectToggle v-bind:interests="value" />
    <interests-search v-bind:interests="value" v-on:input="input(...arguments)" />
  </div>
</template>

<style lang="less" scoped>
#app {
  .interests {
  }
  // end interests
}
</style>

<script>
import dConnectToggle from "@/components/profile/dConnectToggle.vue";
import InterestsSearch from "@/components/profile/InterestsSearch.vue";

export default {
  name: "interests",
  components: {
    dConnectToggle,
    InterestsSearch
  },
  props: {
    value: Array
  },
  methods: {
    input(id, name, approved) {
      this.$emit("input", id, name, approved);
    }
  }
};
</script>
