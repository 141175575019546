<template>
  <div class="profile">
    <alert-modal ref="alert" />
    <!-- <confirm-modal :active="confModal" :confMsg="confMessage" v-on:dismiss="closeModal('confModal')" v-on:confirm="updateProfile"/> -->
    <q-r-code
      :qrCode="qrUrl"
      :active="QRActive"
      v-on:close="dismiss('QRActive')"
    />
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="isPlanner ? plannerBreadcumbs : breadcrumbs" />
        </div>
        <loader site="buyers" :active="loading" />
        <div v-if="!loading">
          <div class="profile-header">
            <div class="header-inner">
              <div class="small-img">
                <div
                  class="delete-img"
                  id="delete-img"
                  v-on:click="removeImg"
                  v-if="profilePic"
                >
                  <font-awesome-icon class="icon-rm" icon="times" />
                </div>
                <div
                  class="mask"
                  v-on:click="
                    smallImg(
                      $event,
                      `file-container-1`,
                      `small-file-1`,
                      `small-image-1`
                    )
                  "
                >
                  <div class="text">Update Profile Picture</div>
                </div>
                <div
                  class="file-upload-wrapper"
                  v-on:click="
                    smallImg(
                      $event,
                      `file-container-1`,
                      `small-file-1`,
                      `small-image-1`
                    )
                  "
                >
                  <img
                    id="small-image-1"
                    class="small-image"
                    :src="profilePic"
                  />
                  <div class="file-upload-container" id="file-container-1">
                    <input
                      accept="image/jpg, image/png , image/jpeg"
                      type="file"
                      name="file"
                      id="small-file-1"
                      class="img-upload"
                    />
                  </div>
                </div>
              </div>
              <div class="general-info">
                <div class="name-container">
                  <h2 class="name-label">
                    {{ user.name }}
                  </h2>
                </div>
                <div class="city-container">
                  <h5 class="city-label">
                    <span>
                      {{ displayCity }}
                    </span>
                  </h5>
                </div>
                <!-- <div class="qr-code-container">
                <diyobo-button 
                  type="primary"
                  txt="Access My QR Code"
                  v-on:click="showQRCode()"
                />
              </div> -->
                <!-- <div class="interest-container">
                                <div class="header-interest" v-for="interest in interests" :key="interest.id">
                                    <div class="interest-inner">{{ interest.label }}</div>
                                </div>
                                <interest
                                    v-for="interest in interests"
                                    :key="interest.id"
                                    :label="interest.interest" 
                                    :pending="false"
                                    :type="'header'"
                                    v-on:remove="removeInterest(interest, null)"    
                                />
              </div>-->
              </div>
            </div>
          </div>
          <div class="divider"></div>
          <Card>
            <FormulateForm
              v-model="personalInfoFormValues"
              @submit="handlePersonalInfoSubmit"
              #default="{ isLoading }"
              autocomplete="false"
              class="personalForm"
            >
              <div class="form-segment">
                <div class="segment-header">
                  <h3>Personal Information</h3>
                </div>
                <div class="row">
                  <div class="col half personalForm">
                    <FormulateInput
                      type="text"
                      name="firstName"
                      label="First Name"
                      placeholder="First Name"
                      validation="required"
                      autocomplete="false"
                    />
                  </div>
                  <div class="col half personalForm">
                    <FormulateInput
                      type="text"
                      name="lastName"
                      label="Last Name"
                      placeholder="Last Name"
                      validation="required"
                      autocomplete="false"
                    />
                  </div>
                  <div class="col half personalForm" style="position: relative">
                    <diyobo-input
                      type="email"
                      label="Email Address"
                      class="profile-input email"
                      placeholder="email@email.com"
                      id="email-input"
                      readOnly
                      v-model="personalInfoFormValues.email"
                      :val="personalInfoFormValues.email"
                      :required="true"
                    />
                    <p>
                      Account Email changes currently unavailable.
                      <router-link to="/contact-us"
                        >Contact support.</router-link
                      >
                    </p>
                  </div>
                  <div class="col half personalForm">
                    <FormulateInput
                      type="tel"
                      name="phone"
                      label="Phone Number"
                      placeholder="(204) 000 - 0000"
                      validation="required"
                      :validation-messages="{
                        min: 'Invalid phone number provided.',
                      }"
                      autocomplete="false"
                    />
                  </div>
                  <div class="col half personalForm">
                    <FormulateInput
                      type="link"
                      name="link"
                      label="Website Link"
                      placeholder="https://yourwebsite.com"
                      autocomplete="off"
                    />
                  </div>
                  <div class="col half personalForm">
                    <FormulateInput
                      type="text"
                      name="birthday"
                      label="Birthday"
                      placeholder="MM/DD/YYYY"
                      validation="optional|date:MM/DD/YYYY"
                      help="Please use the format: MM/DD/YYYY"
                    />
                  </div>
                  <div class="col half personalForm">
                    <FormulateInput
                      type="text"
                      name="street"
                      label="Street Number"
                      placeholder="Street Number / Street Name"
                      @updated="changeLocation"
                      autocomplete="false"
                    />
                  </div>
                  <div class="col half personalForm">
                    <FormulateInput
                      type="text"
                      name="city"
                      label="City"
                      placeholder="City"
                      autocomplete="false"
                    />
                  </div>
                  <div class="col third personalForm">
                    <FormulateInput
                      type="text"
                      name="state"
                      label="State/Province"
                      placeholder="State/Province"
                      autocomplete="false"
                    />
                  </div>
                  <div class="col third personalForm">
                    <FormulateInput
                      type="text"
                      name="zipCode"
                      label="Zip Code"
                      placeholder="Zip Code"
                      autocomplete="false"
                    />
                  </div>
                  <div class="col third personalForm">
                    <FormulateInput
                      type="text"
                      name="country"
                      label="Country"
                      placeholder="Country"
                      autocomplete="false"
                    />
                  </div>
                </div>
              </div>
              <div class="button-wrapper">
                <FormulateInput
                  type="submit"
                  name="Save"
                  :loading="isLoading"
                />
              </div>
            </FormulateForm>
          </Card>
          <!-- end form -->
          <div class="divider"></div>
          <div class="form-segment">
            <!-- <div class="segment-header">
                        <h5 class="segment-title">Your Interests</h5>
                    </div>
                    <interests 
                        v-on:input="addInterest(...arguments)" v-bind:value="interests" 
                    />
                    <div class="interest-container">
                        <interest
                            v-for="interest in interests"
                            :key="interest.interest"
                            :label="interest.interest" 
                            :pending="false"
                            :type="'profile'"
                            v-on:remove="removeInterest(interest, null)"    
                        />
                    </div>
                    <div class="pending-interest-container" v-if="pendingInterests.length > 0">
                        <p>Interests Pending Approval</p>
                        <interest
                            v-for="interest in pendingInterests"
                            :key="interest.interest"
                            :label="interest.interest" 
                            :pending="true"
                            :type="'profile'"
                            v-on:remove="removeInterest(interest, 'pending')"    
                        />
          </div>-->
          </div>
          <!-- end interests -->
          <!-- <div class="divider"></div> -->
          <!--          <Card>-->
          <!--            <form id="theme-settings">-->
          <!--              <div class="form-segment">-->
          <!--                <div class="segment-header">-->
          <!--                  <h3>Theme Preferences</h3>-->
          <!--                </div>-->
          <!--                <div class="row">-->
          <!--                  <div class="col full">-->
          <!--                    <diyobo-checkbox-->
          <!--                      type="check"-->
          <!--                      label="Dark"-->
          <!--                      :checked="theme === 'dark'"-->
          <!--                      @input="updateThemePreference('dark')"-->
          <!--                    />-->
          <!--&lt;!&ndash;                    <diyobo-checkbox&ndash;&gt;-->
          <!--&lt;!&ndash;                      type="check"&ndash;&gt;-->
          <!--&lt;!&ndash;                      label="Light"&ndash;&gt;-->
          <!--&lt;!&ndash;                      :checked="theme === 'light'"&ndash;&gt;-->
          <!--&lt;!&ndash;                      @input="updateThemePreference('light')"&ndash;&gt;-->
          <!--&lt;!&ndash;                    />&ndash;&gt;-->
          <!--                    <diyobo-checkbox-->
          <!--                      type="check"-->
          <!--                      label="System"-->
          <!--                      :checked="theme === 'system'"-->
          <!--                      @input="updateThemePreference('system')"-->
          <!--                    />-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </form>-->
          <!--          </Card>-->
          <!--          <div class="divider"></div>-->
          <Card
            v-if="
              this.$store.state.activeBrand &&
                this.$store.state.activeBrand.subdomain === 'nmp'
            "
            id="webhook"
          >
            <div class="form-segment">
              <div class="segment-header">
                <h3>Webhook</h3>
              </div>
              <div class="row">
                <div class="col full">
                  <p><b>Click Funnel Purchases</b></p>
                  <code>
                    https://api.incredevent.com/api-v2/webhook/nmp/cf-data
                  </code>
                  <p><b>Opt-Ins</b></p>
                  <code>
                    https://api.incredevent.com/api-v2/webhook/nmp/cf-registration
                  </code>
                </div>

                <div class="col full">
                  <p><b>Spiffy Purchases</b></p>
                  <code>
                    https://api.incredevent.com/api-v2/webhook/nmp/spiffy-data
                  </code>
                </div>
              </div>
            </div>
          </Card>
          <div class="divider"></div>

          <Card>
            <form id="email-preferences">
              <div class="form-segment">
                <div class="segment-header">
                  <h3>Email Preferences</h3>
                </div>
                <div class="row">
                  <div class="col full">
                    <diyobo-checkbox
                      type="check"
                      label="I agree to receive important system emails from INCREDEVENT."
                      :checked="emailPreferences.system"
                      :disabled="true"
                    />
                    <diyobo-checkbox
                      type="check"
                      label="I agree to receive instant emails from past events."
                      :checked="emailPreferences.past_events"
                      @input="updateEmailPreference('past_events')"
                    />
                    <diyobo-checkbox
                      type="check"
                      label="I agree to receive promotional emails from INCREDEVENT."
                      :checked="emailPreferences.promotional"
                      @input="updateEmailPreference('promotional')"
                    />
                  </div>
                </div>
              </div>
            </form>
          </Card>
          <div class="divider"></div>
          <Card>
            <FormulateForm
              name="password"
              v-model="passwordFormValues"
              @submit="handlePasswordSubmit"
              #default="{ isLoading }"
              autocomplete="false"
            >
              <div class="form-segment">
                <div class="segment-header">
                  <h3>Update Password</h3>
                </div>
                <div class="row">
                  <div class="col full">
                    <FormulateInput
                      type="password"
                      name="currentPassword"
                      label="Current Password"
                      placeholder="Old Password"
                      validation="required"
                      autocomplete="false"
                    />
                  </div>
                  <div class="col half">
                    <FormulateInput
                      type="password"
                      name="password"
                      label="Password"
                      placeholder="Password"
                      autocomplete="false"
                      help="Must contain 8 characters: 1 Capital, 1 number & 1 special character (! # @ $ * ONLY). Ex: Ticket478!"
                      validation="required"
                    />
                  </div>
                  <div class="col half">
                    <FormulateInput
                      type="password"
                      name="confirmPassword"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      autocomplete="false"
                      help="Must contain 8 characters: 1 Capital, 1 number & 1 special character (! # @ $ * ONLY). Ex: Ticket478!"
                      validation="confirm:password|required"
                    />
                  </div>
                </div>
              </div>
              <div class="button-wrapper">
                <FormulateInput
                  type="submit"
                  name="Change Password"
                  :loading="isLoading"
                />
              </div>
            </FormulateForm>
          </Card>
          <div class="divider"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import Interests from "@/components/profile/Interests.vue";
import Interest from "@/components/profile/Interest.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { getLocation, setLocationCookie } from "@/helpers/location";
import StateAbbreviations from "@/helpers/state-abbreviations";
import { formatPhoneNumber } from "@/helpers/input-formats.js";
import ModalContent from "@/helpers/modals";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import QRCode from "@/components/modals/QRCode.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
// import GoogleAutocompleteInput from "@/components/GoogleAutocompleteInput.vue";
import Loader from "@/components/Loader.vue";
import dayjs from "dayjs";
import Lottie, { lottie } from "lottie-web";

let fireworks = require("@/components/fireworks.json");

library.add(faTimes, faPen);

export default {
  name: "profile",
  components: {
    DiyoboInput,
    Loader,
    Interests,
    Interest,
    DiyoboButton,
    FontAwesomeIcon,
    Breadcrumbs,
    QRCode,
    DiyoboCheckbox,
    // GoogleAutocompleteInput,
  },
  head() {
    return {
      title: "Profile",
    };
  },
  data() {
    return {
      breadcrumbs: [["Profile", null]],
      plannerBreadcumbs: [
        ["Event List", "/dashboard"],
        ["Profile", null],
      ],
      city: "",
      interests: [],
      loading: true,
      pendingInterests: [],
      confModal: false,
      confMessage: "",
      profileComplete: false,
      profilePicture: "/img/placeholders/avatar.png",
      password: "",
      confirmPassword: "",
      oldPassword: "",
      passwordError: false,
      currentPasswordError: false,
      currentPasswordErrorMessage: "",
      passwordErrorMessage: "",
      link: "",
      invalidLink: false,
      oldEmail: "",
      birthday: "",
      defaultCity: "",
      qrUrl: "",
      QRActive: false,
      street: "",
      emailPreferences: {},
      personalInfoFormValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        link: "",
        birthday: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
      },
      passwordFormValues: {
        currentPassword: "",
        password: "",
        confirmPassword: "",
      },
      isPlanner: false,
    };
  },

  computed: {
    displayCity() {
      let user = this.personalInfoFormValues;
      let city = user.city;

      if (user.state) {
        city = city + " " + user.state;
      }

      if (user.country && city) {
        city = city + ", " + user.country;
      } else if (user.country) {
        city = user.country;
      }

      return city;
    },
    profilePic() {
      let user = this.$store.state.user;
      if (!user.profile_img) {
        return "/img/placeholders/avatar.png";
      } else {
        return process.env.VUE_APP_IMAGE_URL + user.profile_img;
      }
    },
    alert() {
      if (this.$store.state.site === "buyers") {
        return this.$bus;
      } else {
        return this.$store.state.bus;
      }
    },
    user() {
      return this.$store.state.user;
    },
    theme: {
      get() {
        return this.$store.state.user.theme;
      },
      set(value) {
        this.$store.commit("setTheme", value);
        this.$store.state.user.theme = value;
      },
    },
  },
  methods: {
    async handlePersonalInfoSubmit(values) {
      this.confModal = false;

      this.$store.state.user.email = values.email;
      this.$store.state.user.name = values.firstName + " " + values.lastName;
      this.$store.state.user.phone = values.phone;
      this.$store.state.user.link = values.link;
      this.$store.state.user.birthday = values.birthday;
      const address = {
        street: values.street,
        city: values.city,
        state: values.state,
        zip_code: values.zipCode,
        country: values.country,
      };
      let self = this;
      this.checkProfileComplete(self);
      await this.$axios
        .post("/buyers/update-profile", {
          oldEmail: self.oldEmail,
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          phone: values.phone,
          link: values.link,
          profileComplete: self.profileComplete,
          birthday: dayjs(values.birthday).format("YYYY-MM-DD"),
          address: address,
        })
        .then((response) => {
          self.createNewCookie(self);
          this.$toast("Profile information updated!", { type: "success" });
          this.updateLocation();
        })
        .catch((error) => console.log(error));
    },
    async handlePasswordSubmit(values) {
      let self = this;

      await this.$axios
        .post("/account/update-password", {
          oldPassword: values.currentPassword,
          email: self.oldEmail,
          password: values.password,
          confirmPassword: values.confirmPassword,
        })
        .then((response) => {
          let data = response.data;
          if (!data.error) {
            const content = ModalContent.changePasswordSuccess;

            this.$formulate.reset("password");

            this.$bus.$emit("alert", {
              title: content.title,
              msg: content.msg,
              confirm: "Okay",
            });
          } else {
            // this.changePasswordErr = data.error.message;
            this.$bus.$emit("alert", {
              title: "Error",
              msg: data.error.message,
            });
          }
          // if (response.data == "Current password is incorrect.") {
          //   this.currentPasswordError = true;
          //   this.currentPasswordErrorMessage = response.data;
          //   this.alert.$emit("alert", {
          //     title: "Error",
          //     msg: response.data,
          //   });
          // } else {
          //   this.createNewCookie(self);
          //   self.oldPassword = "";
          //   self.password = "";
          //   self.confirmPassword = "";
          //   this.alert.$emit("alert", ModalContent.passwordUpdated);
          //   window.scroll(0, 0);
          // }
        })
        .catch((error) => console.log(error));
    },
    updateEmailPreference(type) {
      this.emailPreferences[type] = !this.emailPreferences[type];
      const preference = this.emailPreferences[type];

      this.$axios.post("/buyers/update-email-preference", {
        type,
        value: preference,
      });
    },
    updateThemePreference(theme) {
      theme = "dark";
      this.$axios.post("/account/update-theme", {
        theme,
      });

      this.theme = theme;
    },
    async addInterest(interest) {
      let self = this;
      if (interest.approved == true) {
        this.interests.push(interest);
        updateProfile();
      } else {
        var found = false;
        for (let x = 0; x < this.pendingInterests.length; x++) {
          if (this.pendingInterests[x].interest == interest.interest) {
            found = true;
            break;
          }
        }
        if (found == false) {
          this.pendingInterests.push(interest);
          updateProfile();
        }
      }

      async function updateProfile() {
        await this.$axios
          .post("/buyers/update-profile/interests", {
            email: self.oldEmail,
            interests: self.interests,
            pendingInterests: self.pendingInterests,
            currentInterest: interest.interest,
            type: "add",
            id: interest._id,
          })
          .then((response) => {});
        self.createNewCookie(self);
      }
    },
    async removeInterest(interest, pending) {
      let self = this;
      if (pending == null) {
        this.interests = this.interests.filter((i) => i._id !== interest._id);
      } else if (pending == "pending") {
        this.pendingInterests = this.pendingInterests.filter(
          (i) => i._id !== interest._id
        );
      }

      await this.$axios
        .post("/buyers/update-profile/interests", {
          email: self.oldEmail,
          interests: self.interests,
          pendingInterests: self.pendingInterests,
          currentInterest: interest.interest,
          type: "remove",
          id: interest._id,
        })
        .then((response) => {});
      self.createNewCookie(self);
    },
    closeModal(obj) {
      this[obj] = false;
    },
    checkProfileComplete(self) {
      if (
        this.$store.state.user.email != null &&
        this.$store.state.user.name != null
        // && this.$store.state.user.phone != null
      ) {
        self.profileComplete = true;

        this.$store.commit("updateUserProperty", {
          property: "profile_complete",
          value: true,
        });
      } else {
        self.profileComplete = false;
        this.$store.commit("updateUserProperty", {
          property: "profile_complete",
          value: false,
        });
      }
    },
    createNewCookie(self) {
      // updateSession(self, () => {
      self.$store.state.loading = false;
      // this.alert.$emit("alert", ModalContent.updateProfile);

      // // self.$parent.$refs.alert.open(ModalContent.updateProfile);
      // self.$parent.firstName = self.$store.state.user.name.split(" ")[0];
      // });
    },
    smallImg(e, fileContainer, fileInput, imgId) {
      var heroImg = document.getElementById(fileInput);
      heroImg.click();

      let self = this;
      heroImg.onchange = function() {
        if (!this.files[0]) {
          return;
        }
        // send as FormData to properly handle image uploads
        const data = new FormData();

        data.append("file", this.files[0]);

        self.$axios
          .post("/buyers/update-profile-picture", data)
          .then(({ data }) => {
            if (data.error) {
              self.$toast(
                data.error || "Something went wrong. Please try again.",
                { type: "error" }
              );
            } else {
              let img = data[1];
              self.$store.commit("updateProfileImg", img);

              document.getElementById("delete-img").style.display = "flex";

              self.$toast("Profile picture updated!", { type: "success" });
            }
          });
        // heroImg.value = null;
        document.getElementById(fileContainer).style.display = "none";
      };

      this.$forceUpdate();
    },
    removeImg() {
      let self = this;
      this.profilePicture = "";
      document.getElementById("delete-img").style.display = "none";

      // send as FormData to properly handle image uploads
      const data = new FormData();

      data.append("file", "");

      this.$axios
        .post("/buyers/update-profile-picture", data)
        .then((response) => {
          if (response.data.deleted) {
            self.$store.commit("updateProfileImg", "");

            self.alert.$emit("alert", {
              msg: "Profile picture removed",
            });
          }
        });
    },
    dismiss(val) {
      this[val] = false;
    },

    changeLocation(val) {
      this.personalInfoFormValues.street = val.street_number + " " + val.route;
      this.personalInfoFormValues.city = val.locality;
      this.personalInfoFormValues.state = val.administrative_area_level_1;
      this.personalInfoFormValues.zipCode = val.postal_code;
      this.personalInfoFormValues.country = val.country;
    },
    updateLocation() {
      const city = this.personalInfoFormValues.city;
      const country = this.personalInfoFormValues.country;
      let region = this.personalInfoFormValues.state;

      this.defaultCity = city + " " + region + ", " + country;
      region = StateAbbreviations.getFullName(region);

      // this.city = city + " " + region + ", " + country;

      setLocationCookie(city, region, country);

      this.$axios
        .post("/users/update-location", {
          location: `${city}|${region}|${country}`,
        })
        .then((response) => {});
    },
    showQRCode() {
      this.QRActive = true;
    },
    birthdayDisplayFormat(date) {
      if (date !== "Invalid Date" && date) {
        let arrDate = date.split("-");
        return arrDate[1] + "/" + arrDate[2] + "/" + arrDate[0];
      } else {
        return null;
      }
    },
  },
  created() {
    this.$store.commit("setTitle", "Profile");
    this.isPlanner = ("" + window.location).includes("planners");
  },
  async mounted() {
    this.$store.dispatch(
      "updateHelpVideoSrc",
      "https://youtube.com/embed/x1NnCXm6Jto"
    );
    await this.$axios
      .post(process.env.VUE_APP_SERVICE_URL + "buyers/profile")
      .then((response) => {
        const user = response.data;
        this.personalInfoFormValues.street = user.address.street;
        this.personalInfoFormValues.city = user.address.city;
        this.personalInfoFormValues.state = user.address.state;
        this.personalInfoFormValues.zipCode = user.address.zip_code;
        this.personalInfoFormValues.country = user.address.country;

        this.personalInfoFormValues.email = user.email;
        this.personalInfoFormValues.firstName = user.first_name;
        this.personalInfoFormValues.lastName = user.last_name;
        this.personalInfoFormValues.phone = user.phone ? user.phone : "";

        this.personalInfoFormValues.link = user.link;
        this.$set(
          this.personalInfoFormValues,
          "birthday",
          this.birthdayDisplayFormat(user.birthday)
        );
        // this.personalInfoFormValues.birthday = this.birthdayDisplayFormat(
        //   user.birthday
        // );
        this.interests = user.interests;
        this.pendingInterests = user.pendingInterests;
        this.profilePicture = process.env.VUE_APP_IMAGE_URL + user.profile_img;
        this.qrUrl = process.env.VUE_APP_SERVICE_URL + user.qrCode;
        this.profileComplete = user.profile_complete;
        this.emailPreferences = user.email_preferences;

        let location = user.location;

        if (location) {
          if (location.split("-").length === 3) {
            location = location.replace(/\-/g, "|");
          }

          const parts = location.split("|");

          if (parts.length === 3) {
            const region = StateAbbreviations.getShortName(parts[1]);
            this.defaultCity = `${parts[0]}, ${region}, ${parts[2]}`;
          }
        }

        if (!user.profile_img) {
          this.profilePicture = "/img/placeholders/avatar.png";
        }

        this.pendingInterests.forEach((element) => {
          if (element.approved == true) {
            this.addInterest(element);
            this.removeInterest(element, "pending");
          } else if (element.blacklisted == true) {
            this.removeInterest(element, "pending");
          }
        });

        this.interests.forEach((element) => {
          if (element.blacklisted == true) {
            this.removeInterest(element, null);
          }
        });

        // this.$store.commit("updateInterests", {
        //   interests: this.interests,
        //   pendingInterests: this.pendingInterests,
        // });
        this.loading = false;
      });

    //const location = await getLocation({ $axios: this.$axios });

    // this.city = `${location.city} ${location.region}, ${location.country}`;

    if (this.profilePicture.includes("placeholders")) {
      document.getElementById("delete-img").style.display = "none";

      // document.getElementById("delete-img").style.display = "none";
    }

    if (this.$route.hash) {
      const section = this.$el.querySelector(this.$route.hash);

      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch("updateHelpVideoSrc", null);
  },
};
</script>
<style lang="less" scoped>
#app {
  .profile {
    .profile-header {
      margin-top: 2em;

      .header-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 315px;
        height: 315px;
        margin: auto;
        @media screen and (max-width: 850px) {
          align-items: center;
          justify-content: center;

          .general-info {
            text-align: center;
            width: 100%;
            margin-left: 0px !important;
          }
        }

        .small-img {
          position: relative;
          padding: 3px;
          width: 60%;
          height: 60%;
          max-width: 225px;
          max-height: 225px;
          border-radius: 100%;
          background-image: var(--primary-gradient);

          .mask {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0px;
            left: 0px;
            background-color: rgba(104, 104, 104);
            opacity: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            cursor: pointer;
            transition: 0.5s;

            .text {
              display: flex;
            }
          }

          &:hover {
            .mask {
              display: flex;
              opacity: 0.9;
              transition: 0.5s;
            }
          }

          .file-upload-wrapper {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            overflow: hidden;
            background-color: #666666;
            cursor: pointer;

            .small-image {
              display: block;
              width: inherit;
              height: inherit;
              object-fit: cover;
              object-position: center;
            }
            // end small-image

            .file-upload-container {
              display: none;
              position: absolute;
              display: flex;
              justify-content: center;
              margin: auto;
              width: 0%;
              height: 0%;
              cursor: pointer;

              .img-upload {
                display: none;
              }
              // end img-upload

              .img-upload + label {
                display: flex;
                justify-content: center;
                color: #5b5b5b;
                align-items: center;
                font-size: 12px;
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
              // end img-upload + label
            }
            // end file-upload-container
          }
          // end file-upload-wrapper

          .delete-img {
            position: absolute;
            top: -20px;
            right: -10px;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            font-size: 20px;
            border: solid 2px var(--primary);
            border-radius: 20px;
            width: 30px;
            height: 30px;

            .icon-rm {
              cursor: pointer;
              padding: 5px;
              width: 26px;
              height: 26px;
              // color: black;
            }
          }
          // end delete-img
        }
        // end small-img

        .general-info {
          width: 100%;
          text-align: center;

          .name-container {
            h2.name-label {
              margin-bottom: 0px !important;
              // font-size: 3.5em;
            } // end name-label
          } // end name-container

          .city-container {
            h5.city-label {
              margin: 0px !important;

              span {
                position: relative;

                .icon-pen {
                  position: absolute;
                  right: -22px;
                  top: -5px;
                  cursor: pointer;
                  padding: 5px;
                  height: 10px;
                }
              }
            } // end city-label
          } // end city-container

          .interest-container {
            display: flex;
            flex-wrap: wrap;

            .header-interest {
              margin: 0.25em;
              padding: 3px 5px;
              background-color: #2c3241;
              border: 1px solid #4a639e;
              border-radius: 3px;

              font-size: 0.75em;
            } // end header-interest
          } // end interest-container
        }
        @media screen and (max-width: 600px) {
          width: 250px;
          height: 250px;

          .small-img {
            // padding: 3px;
            .mask {
              .text {
                font-size: 14px;
              }
            }
            .delete-img {
              top: -33px;
            }
          }
        }
        // end general-info
      } // end header-inner
    } // end profile-header

    .form-segment {
      .segment-header {
        h5.segment-title {
          margin-top: -1em;
          margin-bottom: 1em;
          font-size: 1.25em;
        }
      } // end segment-header

      .interest-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: -1em;
        margin-bottom: 10px;
        padding: 0 0.5em;
      } // end interest-container

      .pending-interest-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0em;
        margin-bottom: 10px;
        padding: 0 0.5em;

        p {
          width: 100%;
          margin: 0 0 0.25em 0.25em;
        }
      } // end pending-interest-container
    } // end form-segment
  } // end profile

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  // end of button wrapper
  .input-wrapper.email {
    margin-bottom: 0;
  }
  form {
    scroll-margin-top: 150px;
  }
}
.personalForm {
  flex-basis: auto;
}
</style>
