<template>
  <div>
    <div class="interest-header" v-if="type == 'header'">
      <div class="interest">
        <div class="interest-label">{{ label }}</div>
        <button v-on:click="$emit('remove')">&times;</button>
      </div>
    </div>
    <div class="interest-profile" v-if="type == 'profile' && pending == false">
      <div class="interest">
        <div class="interest-label">{{ label }}</div>
        <button v-on:click="$emit('remove')">&times;</button>
      </div>
    </div>
    <div
      class="interest-profile-pending"
      v-if="type == 'profile' && pending == true"
    >
      <div class="interest">
        <div class="interest-label">{{ label }}</div>
        <button v-on:click="$emit('remove')">&times;</button>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.interest-header {
  margin: 0.25em;
  padding: 2px 6px;
  background-color: #2c3241;
  border: 1px solid #4a639e;
  border-radius: 3px;

  .interest {
    display: flex;
    align-items: center;
    
    font-size: 0.78em;

    button {
      width: 12px;
      appearance: none;
      color: #fff;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
  // end interest-header
}

.interest-profile {
  margin: 0.25em;
  padding: 2px 6px;
  background-color: #363636;
  border: 1px solid #545454;
  border-radius: 3px;

  .interest {
    display: flex;
    align-items: center;
    
    font-size: 0.78em;

    button {
      width: 12px;
      appearance: none;
      color: #fff;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
  // end interest-header
}

.interest-profile-pending {
  margin: 0.25em;
  padding: 2px 6px;
  background-color: #54552e;
  border: 1px solid #545454;
  border-radius: 3px;

  .interest {
    display: flex;
    align-items: center;
    
    font-size: 0.78em;

    button {
      width: 12px;
      appearance: none;
      color: #fff;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
  // end interest-header
}
</style>

<script>
export default {
  name: "interest",
  props: {
    label: String,
    pending: Boolean,
    type: String
  }
};
</script>
